import { BIRDI_REGISTRATION_FLOW_PLAN } from 'enums/plans';
import { graphql, navigate } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';

import IconComposer from 'display-components/icon-composer';
import WorkflowNavigationSection from 'display-components/workflow-navigation/workflow-navigation';

import HealthBenefitsCard from 'components/health-benefits-card/health-benefits-card';
import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import { SelectPlanState } from 'state/membership-registration/membership-registration.reducer';
import { membershipRegistrationGetPlanDetailsRoutine } from 'state/membership-registration/membership-registration.routines';
import { membershipRegistrationSelectPlanListSelector } from 'state/membership-registration/membership-registration.selectors';
import { MembershipPlanId } from 'state/membership/membership.selector';

import { formatPrice } from 'schema/price.schema';

import { TrackEvent } from 'util/google_optimize/optimize_helper';
import { TRACKING_EVENTS } from 'util/registration.util';

import useCustomerIo from 'hooks/useCustomerIo';
import usePageRedirection from 'hooks/usePageRedirection';

import './health-benefits.style.scss';

interface HealthBenefitsPagePageProps {
    data: GatsbyTypes.HealthBenefitsPagePageDataQuery;
}

const MembershipIcon = () => <IconComposer iconType="membership" subIconType="check" />;
const PrescriptionIcon = () => <IconComposer iconType="prescription" subIconType="check" />;
const PharmacistIcon = () => <IconComposer iconType="pharmacist" subIconType="check" />;

const healthBenefitsData = [
    {
        icon: <PharmacistIcon />,
        title: 'Online visits included',
        subtitle: 'Get 1 monthly visit to connect with a licensed healthcare provider.'
    },
    {
        icon: <PrescriptionIcon />,
        title: 'BirdiSelect prescriptions included',
        subtitle: 'Receive 2 BirdiSelect Rxs each month, delivered discreetly to you.'
    },
    {
        icon: <MembershipIcon />,
        title: 'Access to even more medications',
        subtitle: 'Get additional medications up to 80% off, starting at $15 each.'
    }
];

const HealthBenefitsPage = ({ data }: HealthBenefitsPagePageProps) => {
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hasMembershipRedirection = usePageRedirection({
        featureFlag: ENABLE_MEMBERSHIP
    });
    const { trackPageCustomerIo } = useCustomerIo();

    // Selectors
    const selectPlanValues = useSelector(membershipRegistrationSelectPlanListSelector);

    // States
    const [selectedPlan, setSelectedPlan] = useState<SelectPlanState>();
    const firstMonthPayment = selectedPlan?.membershipPlanAttributes[0].pricingMonthly ?? 0;
    const joinFeeAmount = selectedPlan?.membershipPlanAttributes[0].joinFeeAmount ?? 0;
    const planAlias = BIRDI_REGISTRATION_FLOW_PLAN.MEMBERSHIP;

    const trackEvents = () => {
        /** Costumer.io events */
        // Track the current page
        trackPageCustomerIo({ path: window.location.href, plan: planAlias });

        /** Google Analytics events */
        TrackEvent('membership_registration_step3_click', TRACKING_EVENTS.CONTINUE_PAGE_THREE);
    };

    const handleNavigation = () => {
        navigate(-1);
    };

    useEffect(() => {
        dispatch(membershipRegistrationGetPlanDetailsRoutine.trigger());
    }, []);

    useEffect(() => {
        // DRX-4146: we're currently hard-coding the selected plan to be Birdi Care monthly
        setSelectedPlan(selectPlanValues?.filter((plan) => plan.membershipPlanId === MembershipPlanId.CARE)?.[0]);
    }, [selectPlanValues]);

    if (hasMembershipRedirection) {
        return <></>;
    }

    return (
        <WorkflowLayout
            className="health-benefits-page"
            metaData={{ nodeTitle: 'Unlock exclusive health benefits and savings' }}
            isUnauthenticatedFlow={true}
        >
            <LayoutWrapperComponent
                title={t('benefits.title')}
                copyText={t('benefits.subtitle', { monthlyPrice: formatPrice(firstMonthPayment) })}
                headerAlign="left"
                workflowNavigation={
                    <WorkflowNavigationSection
                        limit={4}
                        usage={2}
                        customColor="picton-blue"
                        handleClick={handleNavigation}
                    />
                }
            >
                <div className="health-benefits-page__content">
                    <HealthBenefitsCard healthBenefitsData={healthBenefitsData} />

                    <div className="health-benefits-page__footer">
                        <p>
                            <s>
                                <Trans
                                    i18nKey="benefits.footnote.oneTime"
                                    values={{ oneTime: formatPrice(joinFeeAmount) }}
                                />
                            </s>
                            <br />
                            <Trans i18nKey="benefits.footnote.signUp" />
                        </p>
                    </div>
                </div>

                <ButtonComponent
                    full
                    label="Continue"
                    type="button"
                    onClick={() => {
                        trackEvents();
                        navigate('/get-started-v2/payment-method');
                    }}
                    color="primary"
                    className="health-benefits-page__submit_button"
                />
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default HealthBenefitsPage;

export const query = graphql`
    query HealthBenefitsPagePageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
