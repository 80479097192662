import React from 'react';
import Icon from 'ui-kit-v2/icon/icon';
import ProgressBar from 'ui-kit-v2/progress-bar/progress-bar';

import { WorkflowNavigationSectionProps } from './workflow-navigation.props';
import './workflow-navigation.styles.scss';

const WorkflowNavigationSection: React.FC<WorkflowNavigationSectionProps> = (props) => {
    const { handleClick, hiddenProgressBar, limit = 0, usage = 0, ...progressBarProps } = props;
    return (
        <div className="workflow-navigation-section">
            {handleClick && (
                <button type="button" className="workflow-navigation-section__button" onClick={handleClick}>
                    <Icon icon="small-arrow" width="16" height="12" viewBox="0 0 16 12" />
                </button>
            )}
            {!hiddenProgressBar && <ProgressBar limit={limit} usage={usage} {...progressBarProps} />}
        </div>
    );
};

export default WorkflowNavigationSection;
