export const TRACKING_EVENTS = {
    CONTINUE_PAGE_ONE: 'Continue to page 2, Membership Registration (/setup-your-account)',
    CONTINUE_PAGE_TWO: 'Continue to page 3, Membership Registration (/health-benefits)',
    CONTINUE_PAGE_THREE: 'Continue to page 4, Membership Registration (/payment-methods)',
    CONTINUE_PAGE_FOUR: 'Continue to success page, Membership Registration (/success)',
    PROMOCODE_CLICKED: 'User clicked on Promo code',
    TERMS_OF_USE_CLICKED: 'Navigate to /website-terms-of-use',
    PRIVACY_POLICY_CLICKED: 'Navigate to /website-privacy-policy',
    SUCCESSFUL_REGISTRATION: 'Successful Registration',
    FAILED_REGISTRATION: 'Failed Registration'
};
